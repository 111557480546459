@import '../../assets/common/color';
.job-card {
    position: relative;
    &.active {
        &::before {
            position: absolute;
            top:0;
            left:0;
            bottom: 0;
            content: '';
            width: 5px;
            background-color: $desaturated_dark_cyan;
            @apply rounded-l
        }
    }
    .company {
        color: $desaturated_dark_cyan;
        & > span {
            &:not(:last-child) {
                @apply mr-2;
            }
            &:first-child {
                @apply mr-4;
            }
        }
    }
    .addition-item {
        span {
            &:not(:last-child) {
                @apply mr-3;
            }
        }
    }
}