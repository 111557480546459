@import '../../assets/common/color';
.chain-list {
    li {
        &:not(:first-child):not(:last-child) {
            &::before, &::after {
                content: '•';
                position: absolute;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &::before {
                left: -10px;
            }
            &::after {
                right: -8px;
            }
            position: relative;
            @apply px-2;
            @media (min-width: 1024px) {
                @apply px-5;
                &::before {
                    left: -5px;
                }
                &::after {
                    right: -5px;
                }
            }
        }
        color: $dark_grayish_cyan;
        &:first-child {
            @apply pr-5;
        }
        &:last-child {
            @apply pl-5;
        }
    }
}